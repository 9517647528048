import React, { Component } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';

class About extends Component {
    render() {
        return(
            <div className="pageContainer">
                <Header page="about"/>
                <div style={{margin: "auto", maxWidth: 600}}>
                    <p style={{fontSize: 20, fontWeight: 'bolder'}}>
                        beginnings
                    </p>
                    <p style={{fontSize: 14}}>
                        Words move us. They are telling of time and age. barewords is the beginning of building a curated library of prose and poetry, to forever live on the blockchain. 
                    </p>
                    <p style={{fontSize: 14}}>
                        Previous pioneers in cryptolierature (including <a href="https://encapsuled.xyz/e28" target="_blank" rel="noopener noreferrer">Encapsuled's collection 28</a>) utilized Ethereum Blockchain which in its current Proof of Work state, is expensive and thus limiting to the amount of words one can feasibly store. barewords uses the Polygon Blockchain, Proof of Stake, and stores all text on-chain that is rendered as SVG, making it 100% onchain.
                    </p>
                    <p style={{marginBottom: 50, fontSize: 14}}>
                        9% of all sales from barewords tokens will be redistributed evenly across all contributors (writers and founders) in perpetuity. On barewords, we are all tied together.
                    </p>
                    <p style={{fontSize: 20, fontWeight: 'bolder'}}>
                        future
                    </p>
                    <p style={{marginBottom: 50, fontSize: 14}}>
                        We don’t know where barewords will go. But the vision is clear: a curated library of prose and poetry, accessible for everyone to read. The number of writers onboarded will keep increasing over time, and hopefully it will become a self sustaining decentralized autonomous organization. Together, our words will leave a bigger mark than any of us individually could.
                    </p>
                    <p style={{fontSize: 20, fontWeight: 'bolder'}}>
                        team
                    </p>
                    <p style={{marginBottom: 50, fontSize: 14}}>
                        barewords is run and maintained by the founders <a href="https://twitter.com/encapsuled_/" target="_blank" rel="noopener noreferrer">encapsuled</a> and <a href="https://twitter.com/chromalita/" target="_blank" rel="noopener noreferrer">chromalita</a>. <a href="https://twitter.com/encapsuled_/" target="_blank" rel="noopener noreferrer">encapsuled</a> is a pioneer in the cryptowriters space with one of the first on-chain poetry collections (<a href="https://encapsuled.xyz/e28" target="_blank" rel="noopener noreferrer">Encapsuled 28</a> and <a href="https://encapsuled.xyz/corpus" target="_blank" rel="noopener noreferrer">EncapsuledCorpus</a>). <a href="https://twitter.com/chromalita/" target="_blank" rel="noopener noreferrer">chromalita</a> is a collector and supporter of on-chain writing. 
                    </p>
                    <p style={{fontSize: 20, fontWeight: 'bolder'}}>
                        contribute
                    </p>
                    <p style={{fontSize: 14}}>
                        If you are a writer and would like to contribute to barewords, we are open to chat! As we are just getting off the ground, we require at least five pieces to review to understand the writer’s voice and style.
                    </p>
                    <p style={{marginBottom: 50, fontSize: 14}}>
                        Please keep in mind that we have some limits to render the SVGs in a readable format, i.e. each line has a 100 character limit. <a href="https://discord.gg/MqAsz2MPMB" target="_blank" rel="noopener noreferrer">Join our discord</a> or send us an email to barewords.nft@gmail.com.
                    </p>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default About;