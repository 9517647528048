import React, { Component } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Home from './pages/Home'
import About from './pages/About'
import Writers from './pages/Writers'
import Index from './pages/Index'
import Mint from './pages/Mint'
import Piece from './pages/Piece'
import ToS from './pages/ToS'
import {ABI_T, ADDRESS_T} from './tokensConfig'
import {ABI_M, ADDRESS_M} from './marketplaceConfig'

import Web3 from "web3";
import Web3Modal from "web3modal";

const providerOptions = {};
const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions, // required
  theme: "dark",
});

class App extends Component {
  state = {
    totalSupply: -1,
    tokensContract: null,
    writers: {},
    data: [],
  }

  async componentDidMount() {
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    const chainId = await web3.eth.getChainId();

    if (chainId !== 137) {
        // 137 polygon main 80001 mumbai
        alert("Please switch network to Polygon mainnet")
    } else {
      const tokensContract = new web3.eth.Contract(ABI_T, ADDRESS_T)
      const marketplaceContract = new web3.eth.Contract(ABI_M, ADDRESS_M)
      const totalSupply = await tokensContract.methods.totalSupply().call()
      this.setState({totalSupply, tokensContract})
  
      let data = []
      for (let i=0; i<totalSupply; i++) {
        data[i] = {bids: null, onSale: null}
      }
      await Promise.all(data.map(async (_, index) => {
        let owner = await tokensContract.methods.ownerOf(index).call()
        data[index].index = index
        data[index].owner = owner
        data[index].bids = await marketplaceContract.methods.tokenBids(index).call()
        data[index].onSale = await marketplaceContract.methods.tokenListings(index).call()
      }));
  
      const index = await tokensContract.methods.getIndex().call()
      let writers = {}
      await index.forEach((currElement, index) => {
          if (currElement.author in writers) {
              writers[currElement.author].push({title: currElement.title, tokenId: index})
          } else {
              writers[currElement.author] = [{title: currElement.title, tokenId: index}]
          }
      })
      this.setState({data, writers})
    }
  }
  
  render() {
    return (
      <Router>
        <div>
          <Routes>
            <Route exact path="/" element={<Home data={this.state.data} totalSupply={this.state.totalSupply}/>}/>
            <Route exact path="/about" element={<About/>}/>
            <Route exact path="/writers" element={<Writers/>}/>
            <Route exact path="/index" element={<Index writers={this.state.writers} data={this.state.data}/>}/>
            <Route exact path="/mint" element={<Mint/>}/>
            <Route exact path="/tos" element={<ToS/>}/>
            <Route path="/piece/:tokenId" element={<Piece tokensContract={this.state.tokensContract} data={this.state.data}/>}/>
          </Routes>
        </div>
      </Router>
    );
  }
}

export default App;
