import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class Header extends Component {
    render() {
        return(
            <div style={{margin: 50, marginTop: 20}}>
                <Link to="/" style={{textDecoration: "none"}}><span style={{fontSize:"26px"}}>barewords</span>&nbsp;&nbsp;&nbsp;</Link><span style={{fontSize:"20px"}}>{this.props.page}</span>
            </div>
        )
    }
}

export default Header;