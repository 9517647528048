import React, { Component } from 'react'

class Footer extends Component {
    render() {
        return(
            <div style={{margin: "auto", maxWidth: 600}}>
                <hr/>
                <div style={{margin: 50, textAlign: "center"}}>
                    <div style={{marginBottom: 20}}>
                        <a href="/index">
                            index
                        </a>
                    </div>
                    <div style={{marginBottom: 20}}>
                        <a href="/writers">
                            writers
                        </a>
                    </div>
                    <div style={{marginBottom: 20}}>
                        <a href="/about">
                            about
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;

/*

                    
                    
*/