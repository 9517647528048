import React, { Component } from 'react'
import { Navigate } from 'react-router-dom';

class Home extends Component {

    render() {
        if (this.props.data.length < 1) {
            return(<div style={{maxWidth: 600, margin: "auto"}}><p>loading...</p></div>)
        } else {
            let burned = true
            let tokenId
            while (burned) {
                tokenId = Math.floor(Math.random() * parseInt(this.props.totalSupply))
                if (this.props.data[tokenId].owner !== '0x000000000000000000000000000000000000dEaD') {
                    burned = false
                }
            }
            return(<Navigate to={"/piece/" + tokenId} />)
        }
    }
}

export default Home;