import React, { Component } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';

import Web3 from "web3";

class Index extends Component {

    render() {
        return(
            <div className="pageContainer">
                <Header page="index"/>
                <div style={{maxWidth: 600, margin: "auto"}}>
                    {Object.keys(this.props.writers).sort().map(w => <div key={w}>
                        <p style={{fontSize: 20, fontWeight: 'bolder'}}>
                            {w}
                        </p>
                        <div style={{marginBottom: 50}}>
                            {this.props.writers[w].map((t) => (<span key={t.tokenId}>{
                            this.props.data[t.tokenId].owner === '0x000000000000000000000000000000000000dEaD' ? null : <p style={{fontSize: 14}}>
                                <a href={"/piece/" + t.tokenId}>{t.title}</a>
                                {this.props.data[t.tokenId]?.onSale.isForSale ? <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span style={{fontSize: 12}}>
                                        on sale:&nbsp;
                                        {Web3.utils.fromWei(this.props.data[t.tokenId].onSale.minValue.toString(), 'ether') + ' MATIC'}
                                    </span>
                                </> : null}
                            </p>}</span>))}
                        </div>
                    </div>)}
                </div>
                <Footer/>
            </div>
        )
    }
}

export default Index;