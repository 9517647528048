import React, { Component } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';

class Writers extends Component {
    render() {
        return(
            <div className="pageContainer">
                <Header page="tos"/>
                <div style={{margin: "auto", maxWidth: 600}}>
                    <p style={{fontSize: 20, fontWeight: 'bolder'}}>
                        Terms of Service
                    </p>
                    <p style={{fontSize: 14}}>
                        Any piece minted on the barewords platform and contract must be original work.
                    </p>
                    <p style={{fontSize: 14}}>
                        The first five pieces minted on the barewords platform by an author must be listed for sale.
                    </p>
                    <p style={{fontSize: 14}}>
                        barewords does not control the writing and takes no responsibility for any offensive content. 
                    </p>
                    <p style={{fontSize: 14}}>
                        barewords reserves the right to remove an author and/or their works from the platform, including royalties, for reasons such as misuse. 
                    </p>
                    <p style={{fontSize: 14}}>
                        barewords in the long term will have a dedicated DAO, until such is in place, these decisions will be made by founders. 
                    </p>
                    <p style={{fontSize: 14}}>
                        barewords takes no responsibility for any money lost in the process of minting, selling, or trading tokens.
                    </p>
                    <p style={{fontSize: 14}}>
                        barewords will reserve 1% of all sales for platform maintenance and other operational costs.
                    </p>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default Writers;