import React, { Component } from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';

import Web3 from "web3";
import Web3Modal from "web3modal";

import {ABI_T, ADDRESS_T} from '../tokensConfig'
import Header from '../components/Header';
import Footer from '../components/Footer';
import { CssTextField, BWButton } from '../components/CustomFormComponents';

const providerOptions = {};

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions, // required
  theme: "dark",
});

class Mint extends Component {
    state = {
        title: "",
        lines: [],
        centerAlign: true,
        linesNumber: 1,
        account: null,
        web3: null,
        contract: null,
        mintDisabled: false,
        tosChecked: false,
    }

    async connect() {
        const provider = await web3Modal.connect();
        const web3 = new Web3(provider);
        const chainId = await web3.eth.getChainId();
        const accounts = await web3.eth.getAccounts();
        const contract = await new web3.eth.Contract(ABI_T, ADDRESS_T)
        if (chainId !== 137) {
            // 137 polygon main 80001 mumbai
            alert("Please switch network to Polygon mainnet")
        } else {
            this.setState({account: accounts[0], web3, contract})
        }
    }

    async mint() {
        try {
            this.setState({mintDisabled: true})
            await this.connect()
            if (this.state.contract !== null) {
                const gasPrice = await this.state.web3.eth.getGasPrice()
                console.log("estimated gas price", gasPrice)
                const gasAmount = await this.state.contract.methods
                    .mint(this.state.account, this.state.title, this.state.lines, this.state.centerAlign)
                    .estimateGas({from: this.state.account, value: 0})
                console.log("estimated gas", gasAmount)
                console.log({from: this.state.account, value: 0})
                this.state.contract.methods
                    .mint(this.state.account, this.state.title, this.state.lines, this.state.centerAlign)
                    .send({from: this.state.account, value: 0, gas: String(gasAmount), gasPrice: gasPrice})
                    .on('transactionHash', (hash) => {
                        console.log("transactionHash", hash)
                    })
                    .on('confirmation', (confirmationNumber, receipt) => { 
                        if (confirmationNumber === 1) this.setState({mintDisabled: false})
                    })
                    .on('error', (error, receipt) => {
                        console.log(error)
                        this.setState({mintDisabled: false})
                    }); // If a out of gas error, the second parameter is the receipt.
            } else {
                console.log("Wallet not connected")
            }
        } catch (e) {
            console.log(e)
            alert("Something went wrong. Check the console or try again.")
            this.setState({mintDisabled: false})
        }
    }

    updateLine(lineNumber, value) {
        let bodyLines = this.state.lines.slice()
        bodyLines[lineNumber] = value
        for (let i=0; i<=lineNumber;i++) {
            if (bodyLines[i] === undefined) {
                bodyLines[i] = ""
            }
        }
        this.setState({lines: bodyLines})
    }

    render() {
        let bodyLines = []
        let svgLines = []
        for (let i=0; i<this.state.linesNumber; i++) {
            bodyLines.push(<div style={{marginBottom: 20}} key={i}><CssTextField fullWidth id={"line" + i} label={"Line " + (i+1)} variant="outlined" value={this.state.lines[i]} onChange={(event) => this.updateLine(i, event.target.value)}/></div>)
            svgLines.push(<text key={i} x={this.state.centerAlign ? "50%" : "100"} y={250+40*i} className="base" textAnchor={this.state.centerAlign ? "middle" : "start"}>{this.state.lines[i]}</text>)
        }
        return(
            <div className="pageContainer">
                <Header page="mint"/>
                <div style={{maxWidth: 600, margin: "auto", textAlign:"center"}}>
                    <div style={{marginBottom: 50}}>
                        {this.state.contract === null 
                            ? <BWButton variant="outlined" onClick={() => this.connect()}>connect</BWButton>
                            : <BWButton disabled variant="outlined">connected</BWButton>
                        }
                    </div>
                    <p>create new</p>
                    <div style={{marginBottom: 20}}>
                        <CssTextField fullWidth id="title" label="Title" variant="outlined" value={this.state.title} onChange={(event) => this.setState({title: event.target.value})}/>
                    </div>
                    <div style={{marginBottom: 20}}>
                        <p>text alignment</p>
                        <RadioGroup row aria-label="gender" name="row-radio-buttons-group"
                            value={this.state.centerAlign}
                            onChange={(event) => this.setState({centerAlign: event.target.value === 'true'})}>
                            <FormControlLabel value={true} control={<Radio sx={{ color: '#fafafa', '&.Mui-checked': { color: '#fafafa'} }}/>} label="center" />
                            <FormControlLabel value={false} control={<Radio sx={{ color: '#fafafa', '&.Mui-checked': { color: '#fafafa'} }}/>} label="left" />
                        </RadioGroup>
                    </div>
                    <p>lines</p>
                    <div style={{textAlign: "initial"}}>
                        <p>every line should be maximum 100 characters. You can have as many lines as you want.</p>
                    </div>
                    <div style={{marginBottom: 50}}>
                        <Stack spacing={2} direction="row">
                            <BWButton variant="outlined" onClick={() => this.setState({linesNumber: this.state.linesNumber+1})}>add a line</BWButton>
                            <BWButton variant="outlined" onClick={() => this.setState({linesNumber: this.state.linesNumber-1})}>remove a line</BWButton>
                        </Stack>
                    </div>
                    {bodyLines}
                    <p style={{marginTop: 50}}>preview</p>
                    <div style={{border: "1px solid #fafafa"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" viewBox={"0 0 1200 " + (360+(40*this.state.linesNumber)).toString()}>

                        <rect width="100%" height="100%" fill="#0f0f0f" />
                        <text x="50%" y="150" className="base" textAnchor="middle">
                            {this.state.title}
                        </text>
                        {svgLines}
                    </svg>
                    </div>
                    <div style={{marginBottom: 50, marginTop: 50}}>
                        <p>
                            <Checkbox
                                checked={this.state.tosChecked}
                                onChange={(event) => this.setState({tosChecked: event.target.checked})}
                                sx={{
                                    color: "#fafafa",
                                    '&.Mui-checked': {
                                      color: "#fafafa",
                                    },
                                  }}
                            />
                            I accept the <a href="/tos" target="_blank" rel="noopener noreferrer">Terms of Service</a> (required to mint)
                        </p>
                        {!this.state.tosChecked ? null : this.state.mintDisabled
                            ? <BWButton disabled variant="outlined">minting</BWButton>
                            : <BWButton variant="outlined" onClick={() => this.mint()}>mint</BWButton>
                        }
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default Mint;