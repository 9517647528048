import React, { Component } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';

class Writers extends Component {
    render() {
        return(
            <div className="pageContainer">
                <Header page="writers"/>
                <div style={{margin: "auto", maxWidth: 600}}>
                    <p style={{fontSize: 20, fontWeight: 'bolder'}}>
                        <a href="https://twitter.com/encapsuled_/" target="_blank" rel="noopener noreferrer">encapsuled</a>
                    </p>
                    <p style={{marginBottom: 50, fontSize: 14}}>
                        encapsuled likes to play with words. He has been writing from an early age, sharing the passion with his father.<br/>
                        His writing is rooted in simplicity. The choice of words has always been critical to him, to express ideas in the simplest way possible. 
                        He is influenced by John Fante, Milan Kundera, and Raymond Carver, amongst others.
                    </p>
                    <p style={{fontSize: 20, fontWeight: 'bolder'}}>
                        <a href="https://twitter.com/Devoid_Art" target="_blank" rel="noopener noreferrer">DEVOID</a>
                    </p>
                    <p style={{marginBottom: 50, fontSize: 14}}>
                        DEVOID (Justin Tagg) is a multi-disciplinary storyteller. He runs story studio 'HYPERREAL', a literary press and independent film studio based in London. His Micro Meta Fiction collection on barewords epitomises his work to date - capturing wild, metaphysical ideas and communicating them through tiny, cinematic tales.
                    </p>
                    <p style={{fontSize: 20, fontWeight: 'bolder'}}>
                        <a href="https://twitter.com/writing1thing" target="_blank" rel="noopener noreferrer">W.A.S</a>
                    </p>
                    <p style={{marginBottom: 50, fontSize: 14}}>
                        Gonzo poet<br/>
                        To find, to find the right words amongst a chaos stricken world.<br/>
                        Writing was not his profession, it was his passion.<br/>
                        To transmit that through his poetry was his goal.<br/>
                        These poems are not who he is but who he was.
                    </p>
                    <p style={{fontSize: 20, fontWeight: 'bolder'}}>
                        <a href="https://twitter.com/george_it_is" target="_blank" rel="noopener noreferrer">G E O R G E</a>
                    </p>
                    <p style={{marginBottom: 50, fontSize: 14}}>
                        Spouting shite about life.
                    </p>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default Writers;