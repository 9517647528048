import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import MarketInterface from "../components/MarketInterface"

import { ABI_M, ADDRESS_M } from '../marketplaceConfig'
import { ABI_T, ADDRESS_T } from '../tokensConfig'

import { useParams } from "react-router-dom";
import Web3 from "web3";
import Web3Modal from "web3modal";

const providerOptions = {};

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions, // required
  theme: "dark",
});

export default function Piece(props) {
    let [piece, setPiece] = React.useState(null)
    let { tokenId } = useParams();
    let [contract, setContract] = React.useState(null)
    let [tokensContract, setTokensContract] = React.useState(null)
    let [isApprovedForAll, setIsApprovedForAll] = React.useState(false);
    let [account, setAccount] = React.useState(null)
    let [tokenData, setTokenData] = React.useState(null);
    let [currentTokenId, setCurrentTokenId] = React.useState(null);
    let [marketLoading, setMarketLoading] = React.useState(false);
    let [userWeb3, setUserWeb3] = React.useState(null);
    
    React.useEffect(() => {
        async function getToken() {
            if (!tokenData || (currentTokenId !== tokenId)) {
                setTokenData(props.data[tokenId]);
                setCurrentTokenId(tokenId);
            }
        }
        getToken();
    }, [tokenId, props.data, tokenData, currentTokenId])

    const connect = async () => {
        const provider = await web3Modal.connect();
        const uWeb3 = new Web3(provider);
        const chainId = await uWeb3.eth.getChainId();
        const accounts = await uWeb3.eth.getAccounts();
        if (chainId !== 137) {
            // 137 polygon main 80001 mumbai
            alert("Please switch network to polygon mainnet.")
        } else {
            const contract = new uWeb3.eth.Contract(ABI_M, ADDRESS_M)
            const tokensContract = new uWeb3.eth.Contract(ABI_T, ADDRESS_T)
            const isApprovedForAll = await tokensContract.methods.isApprovedForAll(accounts[0], ADDRESS_M).call()
            setContract(contract)
            setTokensContract(tokensContract)
            setAccount(accounts[0])
            setIsApprovedForAll(isApprovedForAll)
            setUserWeb3(uWeb3)
        }
    }

    const setApprovalForAll = async () => {
        try {
            setMarketLoading(true)
            await connect()
            if (tokensContract !== null) {
                const gasPrice = await userWeb3.eth.getGasPrice()
                console.log("estimated gas price", gasPrice)
                const gasAmount = await tokensContract.methods
                    .setApprovalForAll(ADDRESS_M, true)
                    .estimateGas({from: account, value: 0})
                console.log("estimated gas", gasAmount)
                console.log({from: account, value: 0})
                tokensContract.methods
                    .setApprovalForAll(ADDRESS_M, true)
                    .send({from: account, value: 0, gas: String(gasAmount), gasPrice: gasPrice})
                    .on('transactionHash', (hash) => {
                        console.log("transactionHash", hash)
                    })
                    .on('confirmation', (confirmationNumber, receipt) => { 
                        if (confirmationNumber === 1) {
                          setIsApprovedForAll(true)
                          setMarketLoading(false)
                        }
                    })
                    .on('error', (error, receipt) => {
                        console.log(error)
                        setMarketLoading(false)
                    }); // If a out of gas error, the second parameter is the receipt.
            } else {
                console.log("Wallet not connected")
                setMarketLoading(false)
            }
        } catch (e) {
            console.log(e)
            alert("Something went wrong: " + e.message)
            setMarketLoading(false)
        }
    }

    const delistToken = async () => {
        try {
            setMarketLoading(true)
            await connect()
            if (contract !== null) {
                const gasPrice = await userWeb3.eth.getGasPrice()
                console.log("estimated gas price", gasPrice)
                const gasAmount = await contract.methods
                    .tokenNoLongerForSale(tokenId)
                    .estimateGas({from: account, value: 0})
                console.log("estimated gas", gasAmount)
                console.log({from: account, value: 0})
                contract.methods
                    .tokenNoLongerForSale(tokenId)
                    .send({from: account, value: 0, gas: String(gasAmount), gasPrice: gasPrice})
                    .on('transactionHash', (hash) => {
                        console.log("transactionHash", hash)
                    })
                    .on('confirmation', (confirmationNumber, receipt) => { 
                        if (confirmationNumber === 1) {
                          let tData = tokenData
                          tData.onSale = {0: false}
                          setTokenData(tData)
                          setMarketLoading(false)
                        }
                    })
                    .on('error', (error, receipt) => {
                        console.log(error)
                        setMarketLoading(false)
                    }); // If a out of gas error, the second parameter is the receipt.
            } else {
                console.log("Wallet not connected")
                setMarketLoading(false)
            }
        } catch (e) {
            console.log(e)
            alert("Something went wrong: " + e.message)
            setMarketLoading(false)
        }
    }
    
    const listToken = async (amount, address) => {
        try {
            setMarketLoading(true)
            await connect()
            if (contract !== null) {
                const weiValue = Web3.utils.toWei(amount, 'ether');
                const addressSellTo = address === '' ? '0x0000000000000000000000000000000000000000' : address 
                const gasPrice = await userWeb3.eth.getGasPrice()
                console.log("estimated gas price", gasPrice)
                const gasAmount = await contract.methods
                    .listTokenForSaleToAddress(tokenId, weiValue, addressSellTo)
                    .estimateGas({from: account, value: 0})
                console.log("estimated gas", gasAmount)
                console.log({from: account, value: 0})
                contract.methods
                    .listTokenForSaleToAddress(tokenId, weiValue, addressSellTo)
                    .send({from: account, value: 0, gas: String(gasAmount), gasPrice: gasPrice})
                    .on('transactionHash', (hash) => {
                        console.log("transactionHash", hash)
                    })
                    .on('confirmation', (confirmationNumber, receipt) => { 
                        if (confirmationNumber === 1) {
                          let tData = tokenData
                          tData.onSale = {
                            isForSale: true, 
                            index: tokenId, 
                            seller: account, 
                            minValue: weiValue, 
                            onlySellTo: addressSellTo
                          }
                          setTokenData(tData)
                          setMarketLoading(false)
                        }
                    })
                    .on('error', (error, receipt) => {
                        console.log(error)
                        setMarketLoading(false)
                    }); // If a out of gas error, the second parameter is the receipt.
            } else {
                console.log("Wallet not connected")
                setMarketLoading(false)
            }
        } catch (e) {
            console.log(e)
            alert("Something went wrong: " + e.message)
            setMarketLoading(false)
        }
    }

    const bidOnToken = async (amount) => {
        try {
            setMarketLoading(true)
            await connect()
            if (contract !== null) {
                const weiValue = Web3.utils.toWei(amount, 'ether');
                const gasPrice = await userWeb3.eth.getGasPrice()
                console.log("estimated gas price", gasPrice)
                const gasAmount = await contract.methods
                    .enterBidForToken(tokenId)
                    .estimateGas({from: account, value: weiValue})
                console.log("estimated gas", gasAmount)
                console.log({from: account, value: weiValue})
                contract.methods
                    .enterBidForToken(tokenId)
                    .send({from: account, value: weiValue, gas: String(gasAmount), gasPrice: gasPrice})
                    .on('transactionHash', (hash) => {
                        console.log("transactionHash", hash)
                    })
                    .on('confirmation', (confirmationNumber, receipt) => { 
                        if (confirmationNumber === 1) {
                          let tData = tokenData
                          tData.bids = {
                            hasBid: true, 
                            index: tokenId, 
                            bidder: account, 
                            value: weiValue,
                          }
                          setTokenData(tData)
                          setMarketLoading(false)
                        }
                    })
                    .on('error', (error, receipt) => {
                        console.log(error)
                        setMarketLoading(false)
                    }); // If a out of gas error, the second parameter is the receipt.
            } else {
                console.log("Wallet not connected")
                setMarketLoading(false)
            }
        } catch (e) {
            console.log(e)
            alert("Something went wrong: " + e.message)
            setMarketLoading(false)
        }
    }

    const acceptBidForToken = async () => {
        try {
            setMarketLoading(true)
            await connect()
            if (contract !== null) {
                const gasPrice = await userWeb3.eth.getGasPrice()
                console.log("estimated gas price", gasPrice)
                const gasAmount = await contract.methods
                    .acceptBidForToken(tokenId)
                    .estimateGas({from: account, value: 0})
                console.log("estimated gas", gasAmount)
                console.log({from: account, value: 0})
                contract.methods
                    .acceptBidForToken(tokenId)
                    .send({from: account, value: 0, gas: String(gasAmount), gasPrice: gasPrice})
                    .on('transactionHash', (hash) => {
                        console.log("transactionHash", hash)
                    })
                    .on('confirmation', (confirmationNumber, receipt) => { 
                        if (confirmationNumber === 1) {
                          let tData = tokenData
                          tData.owner = tData.bids.bidder
                          tData.bids = {
                            hasBid: false, 
                          }
                          tData.onSale = {
                            isForSale: false,
                          }
                          setTokenData(tData)
                          setMarketLoading(false)
                        }
                    })
                    .on('error', (error, receipt) => {
                        console.log(error)
                        setMarketLoading(false)
                    }); // If a out of gas error, the second parameter is the receipt.
            } else {
                console.log("Wallet not connected")
                setMarketLoading(false)
            }
        } catch (e) {
            console.log(e)
            alert("Something went wrong: " + e.message)
            setMarketLoading(false)
        }
    }

    const withdrawBidForToken = async () => {
        try {
            setMarketLoading(true)
            await connect()
            if (contract !== null) {
                const gasPrice = await userWeb3.eth.getGasPrice()
                console.log("estimated gas price", gasPrice)
                const gasAmount = await contract.methods
                    .withdrawBidForToken(tokenId)
                    .estimateGas({from: account, value: 0})
                console.log("estimated gas", gasAmount)
                console.log({from: account, value: 0})
                contract.methods
                    .withdrawBidForToken(tokenId)
                    .send({from: account, value: 0, gas: String(gasAmount), gasPrice: gasPrice})
                    .on('transactionHash', (hash) => {
                        console.log("transactionHash", hash)
                    })
                    .on('confirmation', (confirmationNumber, receipt) => { 
                        if (confirmationNumber === 1) {
                          let tData = tokenData
                          tData.bids = {
                            hasBid: false, 
                          }
                          setTokenData(tData)
                          setMarketLoading(false)
                        }
                    })
                    .on('error', (error, receipt) => {
                        console.log(error)
                        setMarketLoading(false)
                    }); // If a out of gas error, the second parameter is the receipt.
            } else {
                console.log("Wallet not connected")
            }
        } catch (e) {
            console.log(e)
            alert("Something went wrong: " + e.message)
            setMarketLoading(false)
        }
    }

    const buyToken = async (amount) => {
        try {
            setMarketLoading(true)
            await connect()
            if (contract !== null) {
                const gasPrice = await userWeb3.eth.getGasPrice()
                console.log("estimated gas price", gasPrice)
                const gasAmount = await contract.methods
                    .buyToken(tokenId)
                    .estimateGas({from: account, value: amount})
                console.log("estimated gas", gasAmount)
                console.log({from: account, value: amount})
                contract.methods
                    .buyToken(tokenId)
                    .send({from: account, value: amount, gas: String(gasAmount), gasPrice: gasPrice})
                    .on('transactionHash', (hash) => {
                        console.log("transactionHash", hash)
                    })
                    .on('confirmation', (confirmationNumber, receipt) => { 
                        if (confirmationNumber === 1) {
                          let tData = tokenData
                          tData.bids = {
                            hasBid: false, 
                          }
                          tData.onSale = {
                            isForSale: false,
                          }
                          tData.owner = account
                          setTokenData(tData)
                          setMarketLoading(false)
                        }
                    })
                    .on('error', (error, receipt) => {
                        console.log(error)
                        setMarketLoading(false)
                    }); // If a out of gas error, the second parameter is the receipt.
            } else {
                console.log("Wallet not connected")
            }
        } catch (e) {
            console.log(e)
            alert("Something went wrong: " + e.message)
            setMarketLoading(false)
        }
    }

    if (props.tokensContract !== null && piece === null) {
        props.tokensContract.methods.tokenURI(tokenId).call().then((newPiece) => setPiece(JSON.parse(Buffer.from(newPiece.substring(29), 'base64').toString('UTF-8'))))
    }

    return(
        <div className="pageContainer">
            <Header page=""/>
            {props.data.length < 1 
                ? <div style={{maxWidth: 600, margin: "auto"}}><p>loading...</p></div>
                : props.data[tokenId].owner === '0x000000000000000000000000000000000000dEaD' 
                    ? <div style={{maxWidth: 600, margin: "auto"}}><p>this text has been burned.</p></div>
                    : piece === null 
                        ? <div style={{maxWidth: 600, margin: "auto"}}><p>loading...</p></div>
                        : <div style={{maxWidth: 600, margin: "auto"}}>
                            {<img src={piece.image} alt={piece.title}/>}
                        <p className="poemAuthor">
                            written by {piece.attributes.Author}
                        </p>
                        <hr/>
                        <MarketInterface 
                            tokenData={props.data[tokenId]}
                            contract={contract}
                            account={account}
                            isApprovedForAll={isApprovedForAll}
                            connect={connect}
                            setContract={setContract}
                            delistToken={delistToken}
                            listToken={listToken}
                            bidOnToken={bidOnToken}
                            acceptBidForToken={acceptBidForToken}
                            buyToken={buyToken}
                            setApprovalForAll={setApprovalForAll}
                            withdrawBidForToken={withdrawBidForToken}
                            loading={marketLoading}
                        />
            </div>}
            <Footer/>
        </div>
    )
    
}